import { registerBlockType } from "@quillforms/blocks";
import { saveImage } from "../service/formDataService";
import $ from 'jquery';

registerBlockType("file-upload", {
  "supports": {
    "editable": true, // If the block has an answer or not.
  },
  // The custom attributes for the block.
  "attributes": {
    "min": {
      "type": "file",
      "default": ''
    },
    
  },

  "display": ({ id, next, attributes, setIsValid, setIsAnswered, setValidationErr, showNextBtn,blockWithError, val, setVal, showErrMsg }) => {

    let chooseFile = "flex";
    let previewFile = "none";
    let imageUrl = '';
    let fileName = '';
    let fileExt = '';

    function customJs (value) {
      
      let chooseFile = document.getElementById('chooseFile');
      let filePreview = document.getElementById('filePreview');

      chooseFile.style.display = 'none';
      filePreview.style.display = 'block';

      const fileReader = new FileReader();
      fileReader.readAsDataURL(value);

      fileReader.onload = () => {
        document.getElementById('setImg').src = fileReader.result;

        document.getElementById('fileName').innerHTML = value.name
      }
    }

    function removeImg(){
      let chooseFile = document.getElementById('chooseFile');
      let filePreview = document.getElementById('filePreview');

      chooseFile.style.display = 'flex';
      filePreview.style.display = 'none';

      document.getElementById('setImg').src = '';
      document.getElementById('fileName').innerHTML = '';

    }

    return <div className="wrapper-sc">
      
      <div className="wrapper-border">
        <input className="input-file" id="input-file" type="file" accept="image/*"  onChange={e => {

          const value = e.target.files[0];
          fileName = value?.name;
          fileExt = value?.name.split('.');
          
          const { required } = attributes
          
          setVal( value );
          showErrMsg( false );

          if ( value ) {
            customJs(value);
            const reader = new FileReader();
            reader.readAsDataURL(value);

            
            reader.onload = () => {

              imageUrl =reader.result ;
              chooseFile = "none";
              previewFile = "flex";

              const imageData = {
                image:reader.result
              }

              saveImage(imageData).then(response => {
                setVal(response.path);
              });
            }

            setIsAnswered( true );
            showNextBtn( true );
            setIsValid(true);
            setValidationErr( null );

          } else {

            setIsAnswered( false );
            showNextBtn( false );

            if( required ) {
              setIsValid(false);
              setValidationErr( "The field is required!" );
            }

          }
        }} />

      </div>
      
      <div className="chooseFile" id="chooseFile" style={{display:'flex'}}>
            <div className="chooseFile-wrapper">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M11.492 10.172l-2.5 3.064-.737-.677 3.737-4.559 3.753 4.585-.753.665-2.5-3.076v7.826h-1v-7.828zm7.008 9.828h-13c-2.481 0-4.5-2.018-4.5-4.5 0-2.178 1.555-4.038 3.698-4.424l.779-.14.043-.789c.185-3.448 3.031-6.147 6.48-6.147 3.449 0 6.295 2.699 6.478 6.147l.044.789.78.14c2.142.386 3.698 2.246 3.698 4.424 0 2.482-2.019 4.5-4.5 4.5m.978-9.908c-.212-3.951-3.472-7.092-7.478-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.522-5.408"/></svg>
            </div>
            <div className="spacer-16px"></div>
            <div aria-hidden="true">
              <div className="choose-file">
                <strong>Choose file</strong>
              </div>
              <div className="drag-drop">or 
                <strong>drag here</strong>
              </div>
            </div>
            <div className="spacer-8px"></div>
            <div aria-hidden="true" className="size-limit">Size limit: 10MB</div>
      </div>
        
      <div className="file-preview-wrapper" id="filePreview" style={{display:'none'}}>
        <div className="file-upload">
          <img className="image-" id="setImg" src={imageUrl} alt="preview of the selected image" />
        </div>
        <div className="preview-footer">
          <div className="file-name">
            <div className="file-name-wrapper">
              <div className="text-wrapper" id="fileName" >{fileName}.</div>
              <div className="extension-wrapper">{fileExt[2]}</div>
            </div>

            <div className="name-spacer"></div>
            <button className="delete-file" onClick={removeImg}>
              <span className="delete-icon">
                <svg height="10" width="10">
                  <path d="M4.6 3.2L7.8 0l1.4 1.4L6 4.6l3.2 3.2-1.4 1.4L4.6 6 1.4 9.2 0 7.8l3.2-3.2L0 1.4 1.4 0l3.2 3.2z"></path>
                </svg>
              </span>
            </button>
          </div>
          
        </div>
      </div>

    </div>
  },
});

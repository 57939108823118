import {useEffect} from 'react';
import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import { registerBlockType } from '@quillforms/blocks';
import { useFieldAnswer } from "@quillforms/renderer-core";
import './App.css';
import { VisitorCount, createUser } from "./service/formDataService";
import "./customBlock"
import $ from 'jquery';

registerCoreBlocks();
const App = () => {

  $(document).on('click','.css-12d8wr1',function(){
    $('#short-text-2').attr('placeholder','Type your answer here...');
    $('#short-text-4').attr('placeholder','Type your answer here...');
    const getTick = $('.tick');
    if(getTick.length == 0){
      $('.first-block .css-12d8wr1 .renderer-core-html-parser').append('<p class="tick"></p>');
    }
  });


  $(document).on('click','.multipleChoice__optionWrapper',function(e){
    
    $('.multipleChoice__optionWrapper').removeClass('border-class');
    $('.multipleChoice__optionWrapper .css-kpt5v9').removeClass('key-color');
    $('.multipleChoice__optionWrapper .tickSelected').remove();
     
    setTimeout(()=>{
      $('.selected').addClass('border-class');
    },100);
    
    $('.selected .css-kpt5v9').addClass('key-color');
    const getLabel = $('.selected .multipleChoice__optionLabel .tickSelected');
    if(getLabel.length == 0){
      $('.selected .multipleChoice__optionLabel').append('<span class="tickSelected"></span>');
    }
    
  });

  useEffect(()=>{

    VisitorCount().then(response => {
      console.log(response);
    });
  },[]);
  
  const fieldAnswer = {
      name:useFieldAnswer(2),
      content_type: useFieldAnswer(3),
      publish_works: useFieldAnswer(4),
      file_upload: useFieldAnswer(5),
      term_condition: useFieldAnswer(6),
    };

  const CreateUser = (e) => {
    createUser(fieldAnswer).then(response => {
      console.log(response)
    })
  }
  
  return (

    <div style={{ width: "100%", height: "100vh" }}>
  
      <Form
        formId="1"
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "1",
              attributes: {
                classnames: "start-screen",
                label: "IAPWE Freelance Writer Application",
                description: "Takes 1 min",
                "buttonText": "Start"
              }
            },
            {
              name: "short-text",
              id: "2",
              attributes: {
                classnames: "first-block",
                label: "Please enter your name"
              }
            },
            {
              name: "multiple-choice",
              id: "3",
              attributes: {
                classnames: "first-block content-block",
                label: "Please select which type of content you are most proficient in",
                verticalAlign: true,
                choices: [
                  {
                    label: "Articles",
                    value: "articles"
                  },
                  {
                    label: "Blog Posts",
                    value: "blog posts"
                  },
                  {
                    label: "Email Copy",
                    value: "email copy",
                  },
                  {
                    label: "Website/Landing Pages",
                    value: "website landing pages"
                  }
                ]
              }
            },
            {
              name: "short-text",
              id: "4",
              attributes: {
                classnames: "first-block",
                label: "Paste a writing sample or links to your published works below",
                description: "(You can also upload a file on the following page)",
              }
            },            
            {
              name: "file-upload",
              id: "5",
              attributes: {
                classnames: "first-block",
                label: "File upload",
                description: "Optional",
              }
            },
            {
              name: "multiple-choice",
              id: "6",
              attributes: {
                classnames: "last-block",
                label: "The current rate of pay for this position is $100 per 300-500 word article. While some of our writers earn $1000 or more per week, this depends solely upon your activity level and, as such, we do not guarantee any specific weekly or monthly earnings for this position.",
                verticalAlign: true,
                choices: [
                  {
                    label: "I acknowledge",
                    value: "acknowledge"
                  },
                  {
                    label: "I don't acknowledge",
                    value: "dont  acknowledge"
                  }
                ],
                buttonText: "Continue",
              }
            }
          ],
        
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            disableNavigationArrows: false,
            disableProgressBar: true
          },
          theme: {
            font: "sans-serif",
            buttonsBgColor: "#9b51e0",
            logo: {
              src: ""
            },
            questionsColor: "#000",
            answersColor: "#0aa7c2",
            buttonsFontColor: "#fff",
            buttonsBgColor:"#0142AC",
            buttonsBorderRadius: 4,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#000",
            progressBarBgColor: "#ccc"
          }
        }}
        onSubmit={(data, { completeForm, setIsSubmitting }) => {
            setIsSubmitting(true);
            completeForm();
            CreateUser(fieldAnswer,'fieldAnswer');

          setTimeout(() => {
          $('.renderer-components-default-thankyou-screen .qf-thankyou-screen-block__content-wrapper .renderer-core-html-parser')
          .html('<h1 class="kPYZOR"><span>To complete your application, send an email with your full name in the subject to </span><a href="mailto:apply@iapwe.org" target="_blank">apply@iapwe.org</a></h1>');          
        }, 500);

          
        }}
      />

    </div>
  );
};

export default App;
